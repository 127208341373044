th, label{
    text-transform: uppercase;
}

/* Ajuste para select2 em elementos ocultos não ficar com tamanho mínimo */

.select2-container {
    width: 100% !important;
    padding: 0;
}

.dataTable tfoot {
    display: table-header-group;
}

#dataTableBuilder_filter {
    float: left;
}


.dt-buttons {
    float: left;
}

.dataTables_length {
    float: left;
}

.dataTables_filter {
    float: right !important;
}

.dtr-title:after {
    content: ':';
}

.dtr-data> form {
    display: inline-block;
}

table.dataTable> tbody> tr.child ul.dtr-details {
    width: 100%;
}

table.dataTable> tbody> tr.child {
    padding: 0;
    text-align: left;
}

table.dataTable> tbody> tr.child ul.dtr-details li {
    padding-left: 10px;
    padding-right: 10px;
}

table.dataTable.dtr-inline.collapsed> tbody> tr> td.child {
    padding: 10px 0;
    background: #FFF;
}

.table> thead> tr> th,
.table> tbody> tr> th,
.table> tfoot> tr> th,
.table> thead> tr> td,
.table> tbody> tr> td,
.table> tfoot> tr> td {
    vertical-align: middle;
}

.content-header h1 > .btn-link {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 3px;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.1rem;
    text-align: left;
    color: #4a90e2;
}

button.dt-button, div.dt-button, a.dt-button, .dt-button, .btn-table {
    border-radius: 1px;
    background-image: none;
    height: 30px;
    text-align: center;
    padding: 0px 21px;
    line-height: 35px;
    background-color: #FFF;
    border: solid 0.5px #dcdcdc;
    color: #455a65;
    line-height: 30px;
}

button.dt-button:hover, div.dt-button:hover, a.dt-button:hover, .dt-button:hover, .btn-table:hover {
    background-color: #efefef;
    color: #222;
    background-image: none ;
    border: none;
}
button.dt-button:hover:not(.disabled), div.dt-button:hover:not(.disabled), a.dt-button:hover:not(.disabled) {
    border: 1px solid #828282;
    background-color: #efefef;
    background-image: none !important;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#efefef', EndColorStr='#efefef');
}

.table, .table.table-striped {
    background: #FFF;
    border: solid 0.5px #dcdcdc;
}

.sidebar-menu li>a>.fa-angle-left, .sidebar-menu li>a>.pull-right-container>.fa-angle-left {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 10px;
    -webkit-transition: transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
}
.sidebar-menu .active>a>.fa-angle-left, .sidebar-menu .active>a>.pull-right-container>.fa-angle-left {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

/** Loading **/

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99990;
    background: url('/img/loading.svg') 50% 50% no-repeat rgba(10, 10, 10, 0.5);
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    height: 40px;
}

.select2-results__option {
    min-height: 40px;
}
.select2-container--bootstrap{
    margin-bottom: 10px;
}
.select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
    margin-top: 0px;
}

@media (max-width: 767px) {
    .skin-black .main-header > .logo {
        background-color: #ffffff !important;
    }
}